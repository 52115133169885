import paths from 'navigation/paths'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { useEffectOnce, useUpdateEffect } from 'react-use'
import {
	resendVerificationRequest,
	sendVerificationRequest,
} from 'redux/verification/reducer'
import { selectVerification } from 'redux/verification/selectors'
import { Button, H2 } from 'share'
import buttonStyles from 'constants/buttonSettings'
import ErrorIcon from '@mui/icons-material/Error'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import { AUTH } from 'constants/localStorageKeys'
import { sendGtagEvent } from 'utils/userUtils'

import './index.scss'

const { TEXT, CONTAINED, BIG_H, FULL_W, SECONDARY } = buttonStyles

const Verification = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { verificationCode } = useParams()

	if (!verificationCode) {
		navigate(paths.notFound)
	}

	const {
		isLoading,
		isVerificationRequestSuccess,
		isVerificationResendAvailable,
		isVerificationResendSuccess,
	} = useSelector(selectVerification)

	const handleResend = () => {
		if (verificationCode) {
			dispatch(resendVerificationRequest(verificationCode))
		}
	}

	useEffectOnce(() => {
		localStorage.removeItem(AUTH)

		dispatch(
			sendVerificationRequest(verificationCode as string)
		)
	})

	useEffect(() => {
		if (isVerificationRequestSuccess) {
			sendGtagEvent('email_verification_complete')

			navigate(paths.invoices)
		}
	}, [isVerificationRequestSuccess])

	useUpdateEffect(() => {
		if (isVerificationResendSuccess) {
			navigate(paths.emailSent)
		}
	}, [isVerificationResendSuccess])

	return (
		<section className="verification">
			{isLoading ? (
				<HourglassTopIcon className="hourglass-icon" />
			) : isVerificationResendAvailable ? (
				<HourglassBottomIcon className="hourglass-icon expired" />
			) : (
				<ErrorIcon className="error-icon" />
			)}

			<H2>Email verification</H2>

			<p className="text-medium">
				{isLoading
					? 'Checking the verification code...'
					: `Code ${isVerificationResendAvailable ? 'has been expired' : 'not found'}`}
			</p>

			{isLoading ? null : isVerificationResendAvailable ? (
				<Button
					type="submit"
					buttonStyle={`${SECONDARY} ${FULL_W} ${BIG_H}`}
					variant={CONTAINED}
					onClick={handleResend}
					disabled={isLoading || !isVerificationResendAvailable}
				>
					{isVerificationResendAvailable
						? 'Resend verification code'
						: 'Checking...'}
				</Button>
			) : (
				<p className="form__footer">
					Already verified?&nbsp;
					<Button
						type="button"
						variant={TEXT}
						buttonStyle="without-uppercase"
						onClick={() => {
							navigate(paths.signIn)
						}}
					>
						Sign in
					</Button>
				</p>
			)}
		</section>
	)
}

export default Verification
