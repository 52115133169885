import buttonStyles from 'constants/buttonSettings'
import paths from 'navigation/paths'
import React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { Button, Logo } from 'share'

import './index.scss'

const { CONTAINED, PRIMARY, SMALL_H } = buttonStyles

const ErrorPageNotFound = () => {
	const navigate = useNavigate()
	const { pathname } = useLocation()

	const pathnameCuted =
		pathname.length > 10 ? `${pathname.slice(0, 10)}...` : pathname

	const onGoBack = () => {
		navigate(paths.invoices)
	}

	return (
		<section className="page-not-found__wrapper">
			<Link to={paths.invoices}>
				<Logo logoStyle="logo-height-big" />
			</Link>
			<p className="page-not-found__paragraph">
				<span className="page-not-found__error-text">404.</span>{' '}
				<span className="page-not-found__gray-text">
					That&rsquo;s an error.
				</span>
				<br />
				The requested URL {pathnameCuted} was not found on this server.{' '}
				<span className="page-not-found__gray-text margin-top">
					That&rsquo;s all we know
				</span>
			</p>
			<Button
				onClick={onGoBack}
				type="button"
				variant={CONTAINED}
				buttonStyle={`${PRIMARY} ${SMALL_H}`}
			>
				Go home
			</Button>
		</section>
	)
}

export default ErrorPageNotFound
