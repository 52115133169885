import { Provider as ReduxProvider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import configStore from 'redux/config'
import { WalletSelectorContextProvider as NearWalletProvider } from 'context/WalletSelectorContext'
import { MetaMaskProvider } from 'metamask-react'
import { WalletProvider as BinanceWalletProvider } from 'react-binance-wallet'
import { SnackbarProvider } from 'notistack';

import App from './App'

import './styles/index.less'
import './styles/resetStyles.scss'
import 'normalize.css'
import '@near-wallet-selector/modal-ui/styles.css'

const Page = (
	<ReduxProvider store={configStore()}>
		<NearWalletProvider>
			<MetaMaskProvider>
				<BinanceWalletProvider>
					<SnackbarProvider
						maxSnack={3}
						autoHideDuration={10000}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
					>
						<App />
					</SnackbarProvider>
				</BinanceWalletProvider>
			</MetaMaskProvider>
		</NearWalletProvider>
	</ReduxProvider>
)

const container = document.getElementById('root')
if (container) {
	const root = createRoot(container)

	root.render(Page)
}
