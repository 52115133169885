import React from 'react'
import { useSelector } from 'react-redux'
import { selectVerification } from 'redux/verification/selectors'
import { Button, H2 } from 'share'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import buttonSettings from 'constants/buttonSettings'
// @ts-ignore
import buildUrl from 'sniper-link/dist/web/esm'
import { sendGtagEvent } from 'utils/userUtils'
import { useEffectOnce } from 'react-use'

import './index.scss'

const { PRIMARY, MEDIUM_H, CONTAINED } =
	buttonSettings

const EmailSent = () => {
	const { email } = useSelector(selectVerification)

	const { link } = buildUrl({
		email,
		from: 'noreply@geekpay.co',
		daysAgo: 1,
	})

	useEffectOnce(() => {
		sendGtagEvent('verification_email_sent')
	})

	return (
		<section className="email-sent">
			<MailOutlineIcon
				classes={{
					root: 'mail-icon m-b-half',
				}}
				color="primary"
			/>

			{link ? (
				<Button
					type="button"
					buttonStyle={`${PRIMARY} ${MEDIUM_H}`}
					variant={CONTAINED}
					onClick={() => window.open(link)}
				>
					Verify your email
				</Button>
			) : (
				<H2>Verify your email</H2>
			)}

			<p className="text-center text-medium m-t-half">
				We’ve sent an email with verification link to&nbsp;
				<b className="text-medium">{email}</b>
				<br />
				The link will expire in a week.
			</p>
		</section>
	)
}

export default EmailSent
