const paths = {
	auth: '/auth',

	signUp: '/auth/signup',

	createUser: '/auth/create-user',

	signIn: '/auth/login',

	otp: '/auth/otp',

	forgotPassword: '/auth/forgot-password',

	verification: '/auth/verifyemail/:verificationCode',

	updateUser: '/auth/updateuser/:verificationCode',

	updatePassword: '/auth/updatePassword/:verificationCode',

	emailSent: '/auth/email-sent',

	main: '/',

	invoices: '/invoices',

	contractors: '/contractors',

	contractor: '/contractor/:teamRoleId',

	clients: '/clients',

	client: '/client/:teamRoleId',

	invoiceReports: '/invoice-reports',

	invoice: '/invoice/:invoiceId',

	pay: '/pay',

	checkout: '/checkout',

	settings: '/settings/:page/:id?',

	companyProfile: '/settings/company-profile',

	companySubscription: '/settings/pricing-plan',

	wallets: '/settings/wallets',

	wallet: '/settings/wallet/:id',

	profile: '/settings/profile',

	printInvoice: '/print/invoice/:invoiceId',

	link: '/link/:role/:protocol/:inviteCode',

	notFound: '*',
}

export default paths
