import EmailSent from 'pages/emailSent'
import ErrorPageNotFound from 'pages/errors/PageNotFound'
import UpdatePassword from 'pages/updatePassword'
import Verification from 'pages/verification'
import React, { lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { MainWrapper } from 'share'
import paths from './paths'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import { IAppRouterProps } from './types'

const AuthWrapper = lazy(() => import('components/AuthWrapper'))
const Auth = lazy(() => import('pages/auth'))
const Invoices = lazy(() => import('pages/invoices'))
const User = lazy(() => import('pages/user'))
const Users = lazy(() => import('pages/users'))
const InvoiceReports = lazy(() => import('pages/invoiceReports'))
const Settings = lazy(() => import('pages/settings'))
const RightBars = lazy(() => import('components/RightBars'))
const Pay = lazy(() => import('pages/pay'))
const Invoice = lazy(() => import('pages/invoice'))
const Checkout = lazy(() => import('pages/checkout'))

const protectedRoutes: IAppRouterProps[] = [
	{
		path: paths.main,
		element: <PrivateRoute component={<MainWrapper />} />,
		children: [
			{
				path: paths.main,
				element: <PrivateRoute component={<RightBars />} />,
				children: [
					{
						path: paths.main,
						element: <Navigate to={paths.invoices} />,
					},
					{
						path: paths.invoices,
						element: <PrivateRoute component={<Invoices />} />,
					},
					{
						path: paths.contractors,
						element: <PrivateRoute component={<Users />} />,
					},
					{
						path: paths.clients,
						element: <PrivateRoute component={<Users />} />,
					},
					{
						path: paths.contractor,
						element: <PrivateRoute component={<User />} />,
					},
					{
						path: paths.client,
						element: <PrivateRoute component={<User />} />,
					},
					{
						path: paths.invoice,
						element: <PrivateRoute component={<Invoice />} />,
					},
					{
						path: paths.pay,
						element: <PrivateRoute component={<Pay />} />,
					},
					{
						path: paths.checkout,
						element: <PrivateRoute component={<Checkout />} />,
					},
					{
						path: paths.settings,
						element: <PrivateRoute component={<Settings />} />,
					},
				],
			},
			{
				path: paths.invoiceReports,
				element: <PrivateRoute component={<InvoiceReports />} />,
			},
			{
				path: paths.notFound,
				element: <PrivateRoute component={<ErrorPageNotFound />} />,
			},
		],
	},
	{
		path: paths.link,
		element: <PrivateRoute component={<Navigate to={paths.invoices} />} />,
	},
]

const publicRoutes: IAppRouterProps[] = [
	{
		path: paths.auth,
		element: <PublicRoute component={<AuthWrapper />} />,
		children: [
			{
				path: paths.auth,
				element: <Navigate to={paths.signIn} />,
			},
			{
				path: paths.signUp,
				element: <PublicRoute component={<Auth type="signUp" />} />,
			},
			{
				path: paths.createUser,
				element: <PublicRoute component={<Auth type="createUser" />} />,
			},
			{
				path: paths.signIn,
				element: <PublicRoute component={<Auth type="signIn" />} />,
			},
			{
				path: paths.otp,
				element: <PublicRoute component={<Auth type="otp" />} />,
			},
			{
				path: paths.forgotPassword,
				element: <PublicRoute component={<Auth type="forgotPassword" />} />,
			},
			{
				path: paths.updateUser,
				element: <PublicRoute component={<Auth type="contractorSignUp" />} />,
			},
			{
				path: paths.verification,
				element: <PublicRoute component={<Verification />} />,
			},
			{
				path: paths.updatePassword,
				element: <PublicRoute component={<UpdatePassword />} />,
			},
			{
				path: paths.emailSent,
				element: <PublicRoute component={<EmailSent />} />,
			},
		],
	},
	{
		path: paths.printInvoice,
		element: <PublicRoute component={<Invoice />} />,
	},
]

const routes = [...publicRoutes, ...protectedRoutes]

export const Routes = () => useRoutes(routes)
