import {
	AUTH,
	INVITE_PROPS, PREVENT_UNLOAD,
	REFUND_PROPS,
	WITHDRAW_PROPS,
} from 'constants/localStorageKeys'
import React from 'react'
import { useParams } from 'react-router'
import { Navigate } from 'react-router-dom'
import ProtectedLayout from 'layouts/ProtectedLayout'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { getUserRequest } from 'redux/user/reducer'
import { selectUser } from 'redux/user/selectors'
import { IRouteParams } from './types'
import paths from './paths'

const PrivateRoute = ({ component }: IRouteParams) => {
	const dispatch = useDispatch()

	const isAuth = localStorage.getItem(AUTH)

	const { user } = useSelector(selectUser)

	const { role, protocol, inviteCode } = useParams()

	const isInvite = inviteCode && protocol && role

	useEffectOnce(() => {
		document.title = 'GeekPay is a platform for cross-border digital payments in USDT'

		if (isAuth && user.id === '') {
			dispatch(getUserRequest())
		}

		if (isInvite) {
			localStorage.setItem(
				INVITE_PROPS,
				JSON.stringify({
					inviteCode,
					protocol,
					role,
				})
			)
		}

		window.onbeforeunload = () => {
			localStorage.removeItem(WITHDRAW_PROPS)
			localStorage.removeItem(REFUND_PROPS)

			return localStorage.getItem(PREVENT_UNLOAD) ? '' : undefined
		}

		return () => {
			localStorage.removeItem(PREVENT_UNLOAD)

			window.onbeforeunload = null
		}
	})

	return isAuth ? (
		<ProtectedLayout>
			{isAuth && user.id === '' ? <span className="loader" /> : component}
		</ProtectedLayout>
	) : (
		<Navigate to={
			isInvite
				? paths.createUser
				: `${paths.signIn}?path=${window.location.pathname}`
		} />
	)
}

export default PrivateRoute
