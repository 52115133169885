import React from 'react'

import { IInvoice } from 'redux/invoice/types'
import { onOpenNotification } from 'redux/notification/reducer'
import { IconButton, Tooltip } from '@mui/material'
import { getCustomInvoiceId } from 'utils/invoiceUtils'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import { useDispatch } from 'react-redux'

import './index.scss'

interface IInvoiceId {
	invoice: Pick<IInvoice, 'id' | 'invoice_id' | 'custom_invoice_id'>,
	className: string,
	hideCopyIcon?: boolean
}

const InvoiceId: React.FC<IInvoiceId> = ({
	invoice,
	className,
	hideCopyIcon = false,
}) => {
	const dispatch = useDispatch()

	const onCopy = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation()

		navigator.clipboard.writeText(
			(invoice.custom_invoice_id || invoice.invoice_id)?.toString() || '',
		).finally()

		dispatch(
			onOpenNotification({
				message: 'Payment request Id copied',
				notificationType: 'success',
			}),
		)
	}

	return (
		<>
			{invoice.custom_invoice_id && invoice.custom_invoice_id.length > 10 ? (
				<Tooltip
					id={invoice.id}
					title={invoice.custom_invoice_id}
					placement="top"
					enterDelay={0}
					leaveDelay={500}
					disableTouchListener
					disableFocusListener
					arrow
				>
					<span className={className}>
						{getCustomInvoiceId(invoice)}
					</span>
				</Tooltip>
			) : (
				<span className={className}>{getCustomInvoiceId(invoice)}</span>
			)}
			{hideCopyIcon ? null : (
				<IconButton
					className="copy-invoice-id"
					onClick={(event) => onCopy(event)}
				>
					<FileCopyOutlinedIcon/>
				</IconButton>
			)}
		</>
	)
}

InvoiceId.defaultProps = {
	hideCopyIcon: false,
}

export default InvoiceId
